import {template} from "lodash"
import pic from "./UHFFZF.png"
import "./style.scss"

let jst = `
<div class="row justify-content-center">
    <div id="ox" class=" col-10 col-md-6 mt-5"></div>
</div>
`

document.addEventListener('DOMContentLoaded', () => {
    let $content = document.getElementById("content");
    $content.innerHTML = template(jst)();

    let img = document.createElement("img");
    img.setAttribute("class", "img-fluid");
    img.src = pic;
    let $ox = document.getElementById("ox");
    $ox.appendChild(img)
});